.brdr_clr {
    border: 1px solid #CDCDCD;
    border-radius: 5px;
    height: 35px;
    width: 100%;
}

.card_respo {
    width: 100%;
}

.card_responsive {
    width: 100%
}

.forms {
    padding: 2rem;
}

.card1,
.card2,
.card3 {
    width: 100% !important;
    padding: 0px;
    border-radius: 10px;
}

.card1 {
    transform: rotate(6deg);
    background-color: #CECBCB!important;
}

.card2 {
    transform: rotate(-3deg);
    background-color: #E4E2E2!important;
}

.card3 {
    transform: rotate(-3deg)
}
.verifyCard{
    transform: rotate(-3deg);
    width: 100% !important;
    padding: 0px;
    position: absolute;
    border-radius: 10px;
}


input:focus {
    border: 1px solid #CDCDCD;
}

.header {
    color: #0693A5;
    font-size: 24px;
    font-weight: bold;
}

.email_header{
    font-size: 24px;
    font-weight: bold;
}

.lable_title {
    color: #0693A5;
    font-weight: 600;
}

.forgot_label {
    color: #0693A5;
    font-weight: 400; 
}
.goto_laebl{
    color: #595e5f;
    font-weight: 700;
}
.error{
    color:red;
    font-size: 14px;
    font-weight: bold;
}
.fa_eye {
    color: #0693A5;
    position: absolute;
    right: 25px;
    top: 10px;
}
.allow{
    cursor: pointer;
}
.notAllow{
    cursor:not-allowed;
}

.verify_card{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    height:fit-content;
}
.go_to_button{
    border: 1px solid #18A7E2;
    padding:5px;
    border-radius: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
    font-weight: 500;
}
.go_to_button:hover{
    background-color: #18A7E2;
    color:white;
}

.gif {
    max-width: 50%;
    height: 200px;
}


