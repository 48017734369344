
.review_brdr{
    border: 1px solid #ced4da ;
    word-break: break-all;
    min-height: 30px;
    padding: 10px;
}
.review_clr{
    color: #18A7E2;
}
.Rselect{
    width: 120px!important;
    margin-right: 10px;
}

 