
.review_brdr{
    border: 1px solid #ced4da ;
    word-break: break-all;
    min-height: 30px;
    padding: 10px;
}
.review_clr{
    color: #18A7E2;
}
.right-menu .Rselect{
    width: 150px!important;
    margin-right: 10px;
    
}
.ToolTipBox{
    position: relative;
}
.ToolTipBox  div{
    display: flex;
    justify-content: space-between;
    align-self: center;
}

.popOver{
    position: absolute;

    flex-direction: column;
    padding: 10px;
    width: 100px;
    align-self: center;
    cursor: pointer;
}
.tooltipbx{
    cursor: pointer;
}
.middle_to{
    margin-top: 1.3rem!important;
}
 #fromDate,#toDate{
 background: #FFFFFF;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 3px;
border:1px solid #fff !important;
height: 35px;
align-self: center;
 }
 table thead tr{
 border: 1px solid #BBBBBB !important;

 }
 .right-menu{
     justify-content: end;
   
 }
