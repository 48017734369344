.tabs-list .react-tabs__tab-list {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 1px solid white;

    text-align: center;
}

.tabs-list .react-tabs>ul {
    display: flex;
    width: 100% !important;
    max-width: 100% !important;
    list-style: none;
    text-align: center;
}

.tabs-list .react-tabs__tab--selected {
    background: #18A7E2;
    border-color: #aaa;
    color: #fff !important;
    border-radius: 5px !important;
  
}

.tabs-list .react-tabs__tab {
    width: 100% !important;
    color: #18A7E2;
}

.react-tabs__tab {
    bottom: 0 !important;
}

@media only screen and (max-width: 767px) {
    .question-tabs .react-tabs__tab-list {
        display: block !important;
    }

    .tabs-list .react-tabs__tab-list {
        display: block;
        width: 100%;
    }

}