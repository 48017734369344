.mg-t {
    margin-top: 5%;
}

.question-tabs .CircularProgressbar {
    width: 80% !important;
}

.question-tabs .circle1 .CircularProgressbar-path {
    stroke: #8EE41E
}

.question-tabs .circle2 .CircularProgressbar-path {
    stroke: #F7CF56
}

.question-tabs .circle3 .CircularProgressbar-path {
    stroke: #FF6D63
}


.question-tabs .d-flex {
    display: flex;
    justify-content: space-between;
}

.question-tabs .marg-align {
    margin-top: 10px;
    margin-left: 21px;
}

.question-tabs .label text:nth-child(2),
.question-tabs .label text:nth-child(3) {
    display: none !important;
}

.question-tabs .score-align {
    transform: translate(-41px, 10px);
}

.question-tabs .speedometer {
    width: 300px;
    max-height: 200px;
    transform: scale(0.75);
}

.question-tabs .fontColor {
    color: #18A7E2;
    white-space: nowrap;
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    .question-tabs .card-body.d-flex {
        display: block !important;
    }

    .question-tabs .recharts-wrapper,
    .question-tabs .recharts-surface {
        width: 100% !important;
        height: 100% !important;
    }

    .circle_card {
        margin: 0 0 20px 20px;
    }

}