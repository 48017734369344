// Background Mixins //
@each $color,
$value in $theme-gradient-colors {
	.bg-gradient-#{$color} {
		background: theme-gradient-color($color);
	}
}

::selection {
	color: white;
	background: #18A7E2;
}
