.curser_btn{
    cursor: pointer;
}
.captcha {
    margin-left: 5rem;
}
.errormessage {
    text-align: left;
    color: red;
    font-size: 13px;
  }
.errormessage1{
    display: none;
}