
input.brder_align.form-control:focus {
    box-shadow: none;
    outline:none;      
}
.dept_sty{
    width: 150px!important;
}
.icons_sty{
    cursor: pointer !important;
}


@media only screen and (max-width: 767px) {
    .question_select {
        margin: 0px;
        width: 100% !important;
    }

    .all_questions {
        margin-top: 30px;
    }

    .quat_btn {
        margin-top: 10px;
    }
}

