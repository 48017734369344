
.deptSelect{
    margin-top: 3px!important;
}
.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center!important;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}
.table_height{
    height: 80px!important;
  width: 160px!important;
  text-align: center!important;
  vertical-align: middle!important;
}
@media only screen and (max-width: 767px) {
    .pdf_respo {

        position: absolute;
        right: 0;
        top: 43px;
        margin-top: 15px;
    }

    .deptbtn_respo {
        position: relative;

    }

    .table_respo {
        margin-top: 5rem;
    }
    
}