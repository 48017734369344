.nowrap_class {

    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}
.export{
    font-size: 14px !important;
    color:#464646 !important;
    padding:6px 0px !important;
    margin: 0px 5px !important;
}

.export:hover{
    background: #18A7E2 !important;
    border-color: #18A7E2 !important;
    color:white !important;
}
.pagination .page-item.active .page-link {
    background: #18A7E2 !important;
    border-color: #18A7E2 !important;
    color: #ffffff !important;
}

.pagination .page-item:hover .page-link {
    background: #18A7E2 !important;
    border-color: #18A7E2 !important;
    color: #ffffff !important;
}
.complaint_v{
    flex-wrap: wrap;
}

.modaldescription{
    border:2px solid lightgrey!important;
    border-radius: 5px;
    min-height: 100px;
    max-width:'80% !important';
    padding:5px!important;
    word-break: break-word;
}
.view_color{
    color: #18A7E2;
}
.dept_selct{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100% !important;
}
.input_width{
    /* width:10rem !important; */
}
 .dropdown-item:active {
    color: #000 !important;
}
@media only screen and (max-width: 767px) {
    .complaint_pdf {
        position: relative;
    }

    .complaintrespo_pdf {
        position: absolute;
        right: 0;
        top: 0;
    }

    .select_respo {
        margin-top: 10px;
    }
    .input_width {
        width:70% !important;
    }
    .search_icon {
        position: absolute;
        right: 5%;
        bottom: 10px;
        color: #18A7E2;
    }
    .search_btn{
        position: absolute;
        right: 34%;
        bottom: 10px;
        color: #18A7E2;
    }
    .export_respo{
    position: absolute;
    right: -8px;
    top: 75px;
    }
    .margin_form{
        margin-top: 25%;
    }
    .select_rspo{
    width: 70% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
    }
}

@media (min-width:768px) {
    .table_style {
        display: inline-table !important;
    }
    
}
.whiteSpace{
    white-space: break-spaces !important;
}