.Btd_img{
    width:20px!important;
    height:20px!important;
    margin-right: 10px;
    cursor: pointer;
}

.ctdImg{
  width:20px!important;
  height:20px!important;
  cursor: pointer;
}
.details{
    display: grid;
    grid-template-columns:40% 60%;
    margin: 2% 0% 1% 0% !important;
    text-align: start;
    align-items: center;
  }

  .details1{
    display: grid;
    grid-template-columns:40% 40% 20%;
    margin: 2% 0% 1% 0% !important;
    text-align: start;
    align-items: center;
  }
  /* .toast_sty{
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .modal_button{
    display: flex;
    justify-content: center!important;
  }


.my_modal{
  max-width: 100%;
  -webkit-overflow-scrolling: none;
}
.upload_sty{
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 5px;   
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF!important;
  color: #000000;
}

.upload_sty:hover{
  background-color: #18A7E2!important;
  color:white!important;
}
.buttons{
    display: flex;
}
.fa_eye1{
  color: #0693A5;
    position: absolute;
    right: 25px;
    top: 34px;
}

.branchTable tbody th,
.branchTable tbody td {
  padding:10px !important;
  text-align: center !important;
  overflow: auto!important;
  width: 300px;
} 
.grid-container{
  display:grid;
  /* grid-template-columns: auto auto auto;   */
  padding: 10px; 
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(280px, 1fr)); 
}

.mobileno{
  margin-bottom: 0px !important;
  font-size: smaller;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.crdinfo{
  box-sizing: border-box;
  border: 2px solid #BBBBBB;
  border-radius: 10px;
  padding: 6px;
}

.crdinfo:hover{
  border: 2px solid #18A7E2 ;
}
/* .branch_info:hover{
  border: 2px solid #18A7E2 ;
} */


.branch-body h5{
  font-size: 0.9rem;
  color: #18A7E2 !important;
  }


.branchname{
  white-space: nowrap; 
  width: 100px; 
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}

.edit_delete{
  display: flex;
  justify-content: end;
}

.branch-body{
  padding: 0.25rem;
  /* max-width: 7.5rem; */
  padding-left: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 85%;
}

.subadmintitle{
  margin-bottom: 0px;
  margin-top: 5px;
}

.branchTable tbody td a {
  color: #000;
}

.branchTable  th {
  text-align: center!important;
  padding:1.3px
}
@media screen and (min-width: 992px) {
.my_modal.modal-lg,.my_modal.modal-xl {
    max-width: 800px;

}
}
@media  (min-width:320px) and (max-width:1340px){
  .grid-container{
    display:grid;
    /* grid-template-columns: auto auto auto;   */
    padding: 10px; 
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr))!important; 
  }
}
@media (max-width:400px) {
  .crdinfo{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #BBBBBB;
    border-radius: 10px;
    padding: 6px;
    width:70%;
    text-align: center;
  }
  .grid-container{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
