.recharts-xAxis {
  font-size: 10px;
}
.card .card-body {
  padding: 0.5rem 1.7rem;
}

.drops {
  -webkit-appearance: listbox !important;
}

.card .percent-align {
  position: absolute;
  top: 29%;
  left: 45%;
  color: #18a7e2;
}

.percent_card {
  position: relative;
}

.pie {
  position: relative;
}
.text-rgt {
  justify-content: right;
  text-align: right;
}
.nps1,
.nps2,
.nps3 {
  color: #18a7e2;
  font-weight: 700;
}
.nps1 {
  position: absolute;
  left: 43%;
  top: 42%;
}
.nps2 {
  position: absolute;
  left: 47.5%;
  top: 8.5%;
}
.nps3 {
  position: absolute;
  left: 82.5%;
  top: 8.5%;
}

.bread_crumb {
  margin-top: 20px;
}
g.recharts-layer.recharts-cartesian-axis-tick {
  display: none;
}
.DropdownLabel {
  margin: 0px 10px;
}
.card-border {
  border-radius: 10px;
  padding: 2.5rem 0px;
  box-shadow: rgba(202, 200, 200, 0.2) 0px 4px 8px 0px,
    rgb(0 0 0 / 19%) 0px 6px 20px 0px;
  border-top: 0.5px solid rgb(235, 230, 230);
}
.question_sty {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .dash-align .recharts-wrapper,
  .dash-align .recharts-surface {
    width: 100% !important;
    height: 100% !important;
  }

  #exampleSelectGender {
    width: 100%;
  }

  .drops {
    margin: 0;
  }
  .dashboard_drop {
    margin-top: 26%;
  }
  .nps1 {
    top: 40%;
  }
  .nps2 {
    position: absolute;
    left: 42%;
    top: 33.5%;
  }
  .nps3 {
    position: absolute;
    left: 42%;
    top: 60%;
  }
  .calender_respo {
    position: absolute;
    right: 0;
    bottom: -10px;
  }
  .date_height {
    display: grid;
    margin: 0 10px 0 10px;
  }
  .dateDashboard_responsive {
    margin-top: 8%;
  }
  .calender_submit {
    margin-top: 5%;
  }
  .date_calender {
    width: 13rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .page-header {
    display: flex !important;
    flex-direction: column;
  }

  .dash-align .recharts-wrapper,
  .dash-align .recharts-surface {
    width: 100% !important;
    height: 100% !important;
  }

  .card .card-body {
    padding: 1rem;
  }

  .nps2 {
    position: absolute;
    left: 45.5%;
    top: 10.5%;
  }
  .nps3 {
    position: absolute;
    left: 81.5%;
    top: 10.5%;
  }
}
.rdt_TableHeadRow {
  background-color: #989a9e !important;
  border-bottom: 2px solid #fff !important;
  font-size: 15px !important;
}
.rdt_TableCol {
  border-left: 2px solid #fff !important;
}
.rdt_TableRow {
  border-bottom: 2px solid #fff !important;
}
.giEyCo {
  padding: 8px !important;
}
