/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
  background: $content-bg;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0;

    .main-panel {
      width: 100%;
      transition: none;

      .content-wrapper {
        padding: 0;

        >div {
          height: 100%;
        }
      }
    }
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);

  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }

  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {

  padding: 0.25rem 2.25rem;
  width: 100%;
  @include flex-grow(1);
}

.page_inner {
  background: $content-bg;
  padding: 2.25rem 2.25rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 1rem 0rem 0rem 6rem;
}

.container-scroller {
  overflow: hidden;
  padding: 0 3rem;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-md-flex;
  @extend .justify-content-end;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;

  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}

.page-title {
  color: $sidenavColor;
  font-size: 20px;
  margin-bottom: 0;

  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);

    i {
      font-size: .9375rem;
      line-height: 36px;
    }
  }
}

.legend-dot {
  display: inline-block;
  padding: 6px;
  background-color: rgba(142, 228, 30, 1);
}

.legend-dots {
  display: inline-block;
  padding: 6px;
  background-color: rgba(255, 109, 99, 1);
}

.legend-dotse {
  display: inline-block;
  padding: 6px;
  background-color: rgba(247, 207, 86, 1);
}