html {
  scroll-behavior: smooth;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
}

.green-toast {
  background-color: #18a7e2 !important;
  color: white; /* Adjust text color for better visibility */
}

.Toastify__toast-theme--light {
  background: #18a7e2 !important;
  color: #fff !important;
}

input {
  background-color: white;
  color: black;
  border: 1px solid #ebedf2;
  padding: 0.25rem !important;
  padding-left: 10px !important;
  border-radius: 8px;
}

.css-50etie svg circle {
  stroke: #18a7e2 !important;
}
._loading_overlay_content{
  color: #18a7e2;
}

