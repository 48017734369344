.upload_any {
    background-image: url(../../assets/images/icons/uploadbg.png);
    background-size: 100% 100%;
    height: 100%;
}


.saver_buttons {
    display: flex;
    justify-content: space-around;

}

.upload_btn {
    background-color: #18A7E2;
    border: #18A7E2;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.upload_btn:hover {
    background-color: #18A7E2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #18A7E2 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border: #18A7E2;
} */

/* .btn-primary:not(.btn-light):focus {
    background-color: #18A7E2 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border: #18A7E2;
} */
.btn-primary:not(.btn-light):focus{
    background-color:#18A7E2 !important;
}

.upload_btn{
    background-color: #18A7E2 !important;
    color: white !important;
    border: 1px solid #18A7E2 !important;
}

.device_icons{
    display: block;
    margin: auto;
}
.desktopImage{
    width: 50%;
    align-self: center;
}
@media only screen and (max-width: 767px) {
    .smalldevice {
        margin-top: 20px;
       
    }}