/* Layouts */

.navbar {
  &.fixed-top {
    +.page-body-wrapper {
      padding-top: $navbar-height;
    }
  }

  background-color: white;
}


// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-mini;
      }

      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-mini});
      }
    }

    .sidebar {
      width: $sidebar-width-mini;

      .nav {
        .nav-item {
          padding: 0;
          margin: 0;

          .nav-link {
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(column-reverse);
            text-align: center;
            position: relative;
            border-bottom: none;

            .menu-title {
              display: block;
              margin: auto;
            }

            .menu-sub-title {
              margin: auto;
            }

            .badge {
              margin-left: 5px;
              display: none;
            }

            i {
              &.menu-icon {
                display: block;
                margin-right: auto;
                margin-left: auto;
              }

              &.menu-arrow {
                display: inline-block;
                margin-left: 5px;
                position: absolute;
                top: 50%;
                right: 10px;
                @include transform(translateY(-50%));

                &:before {
                  content: "\F140";
                }
              }
            }

            &[aria-expanded="true"] {
              .menu-arrow {
                &:before {
                  content: "\f143";
                }
              }
            }
          }
        }
      }
    }

    .main-panel {
      width: calc(100% - #{$sidebar-width-mini});
    }

    &:not(.sidebar-icon-only) {

      //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
      .sidebar {
        .nav {
          &:not(.sub-menu) {
            >.nav-item {
              border-bottom: 1px solid rgba($sidebar-dark-menu-color, .2);

              .nav-link {
                height: auto;
                padding: $sidebar-mini-menu-padding;

                i {
                  &.menu-icon {
                    margin-bottom: .5rem;
                  }
                }
              }
            }
          }

          &.sub-menu {
            padding: 0;
            border-top: none;

            .nav-item {
              .nav-link {
                padding: 7px 0 7px 25px;
                margin-left: auto;
                margin-right: auto;
                display: block;
                text-align: left;
                width: 66%;
              }
            }
          }
        }
      }
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;

        .brand-logo {
          display: none;
        }

        .brand-logo-mini {
          display: inline-block;
        }
      }

      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }

    .sidebar {
      width: $sidebar-width-icon;

      .nav {
        overflow: visible;

        .nav-item {
          position: relative;
          padding: 0;

          .nav-link {
            display: block;
            text-align: center;

            .menu-title,
            .badge,
            .menu-sub-title {
              display: none;
            }

            .menu-title {
              @include border-radius(0 5px 5px 0px);

              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);
              }
            }

            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
              }

              &.menu-arrow {
                display: none;
              }
            }

            &[aria-expanded] {
              .menu-title {
                @include border-radius(0 5px 0 0px);

                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);
                }
              }
            }
          }

          &.nav-profile {
            display: none;
          }

          &.nav-category {
            display: none;
          }

          &.nav-doc {
            margin: 0;

            i {
              display: block;
            }
          }

          .collapse {
            display: none;
          }

          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $white;

                @at-root #{selector-append(".sidebar-dark", &)} {
                  background: $sidebar-dark-menu-active-bg;
                }

                padding: 0.5rem 0.5rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 0px 5px 5px 0px;

                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                }

                @at-root #{selector-append(".sidebar-dark", &)} {
                  color: $white;
                }

                &:after {
                  display: none;
                }
              }
            }

            .collapse,
            .collapsing {
              display: block;
              background: $icon-only-menu-bg-light;
              @include border-radius(0 0 5px 0);

              @at-root #{selector-append(".sidebar-dark", &)} {
                background: $sidebar-dark-bg;
              }

              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;

              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
                @include border-radius(0 0 0 5px);
              }
            }
          }
        }

        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;

          .nav-item {
            .nav-link {
              text-align: left;
              padding-left: 20px;
            }
          }

          @at-root #{selector-append(".rtl", &)} {
            .nav-item {
              margin-right: auto;
              margin-left: 0;

              .nav-link {
                text-align: right;

                &:before {
                  left: 0;
                  right: unset;
                }
              }
            }
          }
        }
      }

      .sidebar-actions {
        display: none;
      }
    }

    .main-panel {
      width: calc(100% - #{$sidebar-width-icon});
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .sidebar {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      width: 0;
    }

    .main-panel {
      width: 100%;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      position: relative;

      .sidebar {
        transition: none;
      }
    }

    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
        height: 100%;
        -webkit-box-shadow: 0 0 3px 1px #a7a3a3;
        /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow: 0 0 3px 1px #a7a3a3;
        /* Firefox 3.5 - 3.6 */
        box-shadow: 0 0 3px 1px #a7a3a3;
        /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      }
    }

    .main-panel {
      width: 100%;
      transition: none;
    }
  }
}


//Fixed sidebar
.sidebar-fixed {
  @media(min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;

      .nav {
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto;
        position: relative;

        &.sub-menu {
          max-height: none;
        }
      }
    }

    .main-panel {
      margin-left: $sidebar-width-lg;
    }

    &.sidebar-icon-only {
      .main-panel {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 992px) {
    .container-scroller {
      background: #e1d7ea;
      padding: 0 calc((100% - #{$boxed-container-width}) / 2);
    }

    .navbar {
      &.fixed-top {
        margin: auto;
        max-width: $boxed-container-width;
      }
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;

  .sidebar {
    .nav {
      padding-right: 0;
    }
  }

  .product-chart-wrapper,
  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
}

.sidebar-icon-only {
  background-image: url(../images/icons/bg_image.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  // background-position: center;
  background-size: cover;
}

.table_style {
  color: #464646;

}

.table_style,
th,
td {
  border: 1px solid #BBBBBB !important;
  text-align: center;
}

.brder_align {
  border: 1px solid #BBBBBB;
  height: 30px;
  padding: 0;
  text-indent: 10px;
  max-width: 100%;
  width: 100%;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.react_icons {
  width: 16px !important;
  height: 16px !important;
}

.react-bootstrap-table table.table thead th {
  padding: 0.9375rem 0.9375rem 0.9375rem 0.9375rem;
}


.quat {
  color: $sidenavColor;

}

.question {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quat_btn {
  float: right;
  padding: 10px 15px;
  color: white;
  background-color: $sidenavColor !important;
  border: 1px solid $sidenavColor  !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
}

.pdf_icon {
  font-size: 24px;
  color: #AC0F0F;
  cursor: pointer;
}
.excel_icon{
  color: #00733B;
  cursor: pointer;
  font-size: 24px;
}

.pdf_align {
  display: flex;
  align-items: center;
  height: 35px;
  margin-right: 10px;
}

.quat_btn:hover {
  background-color: $white  !important;
  color: $sidenavColor  !important;
}

.quat_btn:active {
  background-color: $white  !important;
  background: $sidenavColor  !important;
  color: $white  !important;
}

.quat_btn:focus {
  background-color: $white  !important;
  background: $sidenavColor  !important;
  color: $white  !important;
}

.form_drop {
  height: 35px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}

.td_img {
  width: 14px !important;
  height: 18px !important;
  border-radius: 0 !important;
  cursor: pointer;
}

.form_head {
  position: relative;
  margin-right: 13px;
  margin-bottom: 0 !important;
}

.search_icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #18A7E2;
}

// Modal //
.modal_btn {
  background-color: $sidenavColor;
  color: white;
  border: 1px solid $sidenavColor;
  border-radius: 5px;
}

.modal_btn:hover {
  background-color: $white !important;
  color: $sidenavColor  !important;
  border: 1px solid $sidenavColor !important;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.pagination{
  margin-top: 1rem;
}
.modal_title {
  text-align: center;
  color: $sidenavColor;
}

.close {
  color: $sidenavColor  !important;
}

button#pageDropDown {
  background-color: #18A7E2;
  border: #18A7E2;
}

// .icon {
//   width: 20px !important;
//   height: 20px !important;
// }
// .page-item:hover {
//   background-color: #18A7E2 !important;
// }

// .page-item.disabled .page-link {
//   background-color: #18A7E2 !important;
//   color: white !important;
//   border-color: #18A7E2 !important;
// }

// .page-link {
//   background-color: #18A7E2 !important;
//   color: white !important;
//   border-color: #18A7E2 !important;

// }

// .pagination .page-item.active .page-link {
//   background-color: #18A7E2 !important;
//   border-color: #18A7E2 !important;
// }

.label_img{
  padding: 10px;
  background: red; 
  display: table;
  color: #fff;
}
.input_img[type="file"] {
  display: none;
}
button#dropdown-basic {
  background-color: white;
  color: black;
  border: 1px solid #ebedf2;
  padding: 0.65rem!important;
  padding-left: 15px!important;
}
.show > .btn-success.dropdown-toggle:focus{
  box-shadow: none !important;
}
.btn-success:focus{
  box-shadow: none !important;
}
.picker_arrow{
  font-size: 24px;
  color: #18A7E2;
}
.picker_to{
  padding: 0px 10px;
}

.calendar_sharp{
  color: #18A7E2;
  font-size: 24px;
  cursor: pointer;
}
.calender_picker{
  background-color: white !important;
  border: none !important;
}
.calender_picker.btn-primary:not(.btn-light):focus {
  background-color: white !important;
  box-shadow: none !important;
}
.calender_picker:after{
  display: none !important;
}
.date_calender{
  width: 22rem;
}
.calender_submit{
  margin: auto;
  display: block !important;
  background-color: #18A7E2 !important;
  border: 1px solid #18A7E2 !important;
  text-align: center!important;
  line-height: 6px;
  width: fit-content;
  padding: 13px 13px;
}
.date_height{
  height: 43px;
  font-size: 16px;
}


.notify-align, .notify-align:hover {
  text-decoration:none;
  color:#000;
}
button.swal2-confirm.swal2-styled{
  background-color: #18A7E2 !important;
}
@media only screen and (max-width: 767px) {
  .review_calender{
    position: absolute;
    right: 0;
  }
  .form_head{
    margin-right: 0;
  }
}
.swal2-confirm{
  background-color: #AC0F0F !important;
}